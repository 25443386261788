import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions, StrategyHandler, Strategy } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { SyncType } from 'o365.pwa.types.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts' {
    export interface ISyncError<T extends string> {
        errorCode: T
        serializedErrorObject?: string;
    }

    export type OfflineSyncProgressErrorCodes = OfflineSyncProgressGenerateOfflineDataErrorCodes 
        | OfflineSyncProgressRetrieveRowCountErrorCodes 
        | OfflineSyncProgressRetrieveRecordsErrorCodes
        | OfflineSyncProgressRetrieveFilesErrorCodes 
        | OfflineSyncProgressRecordRetrieveErrorCodes 
        | OfflineSyncProgressRecordStoreErrorCodes
        | OfflineSyncProgressFileRetrieveErrorCodes 
        | OfflineSyncProgressFileStoreErrorCodes
        | 'OFFLINE-SYNC-UNKOWN-ERROR';

    export type OfflineSyncProgressGenerateOfflineDataErrorCodes = 'GENERATE-OFFLINE-DATA-UNKOWN-ERROR'
        | 'GENERATE-OFFLINE-DATA-RESPONSE-ERROR'
        | 'GENERATE-OFFLINE-DATA-OBJECT-STORE-NOT-FOUND'
        | 'GENERATE-OFFLINE-DATA-OBJECT-STORE-VERSION-MISMATCH';
    export type OfflineSyncProgressRetrieveRowCountErrorCodes = 'RETRIEVE-ROW-COUNT-UNKOWN-ERROR'
        | 'RETRIEVE-ROW-COUNT-NO-RECORDS-ERROR';
    export type OfflineSyncProgressRetrieveRecordsErrorCodes = 'RETRIEVE-RECORDS-UNKOWN-ERROR'
        | 'RETRIEVE-RECORDS-RESPONSE-PARSE-ERROR'
        | 'RETRIEVE-RECORDS-RESPONSE-READER-MISSING-ERROR';
    export type OfflineSyncProgressRetrieveFilesErrorCodes = 'RETRIEVE-FILES-UNKOWN-ERROR';
    export type OfflineSyncProgressRecordRetrieveErrorCodes = 'RECORD-RETRIEVE-UNKOWN-ERROR';
    export type OfflineSyncProgressRecordStoreErrorCodes = 'RECORD-STORE-UNKOWN-ERROR';
    export type OfflineSyncProgressFileRetrieveErrorCodes = 'FILE-RETRIEVE-UNKOWN-ERROR';
    export type OfflineSyncProgressFileStoreErrorCodes = 'FILE-STORE-UNKOWN-ERROR';

    export type OnlineSyncProgressErrorCodes = OnlineSyncProgressErrorCodesRetrieveRowCountErrorCodes
        | OnlineSyncProgressErrorCodesUploadRecordsErrorCodes
        | OnlineSyncProgressErrorCodesUploadFilesErrorCodes
        | OnlineSyncProgressErrorCodesRecordUploadErrorCodes
        | OnlineSyncProgressErrorCodesFileUploadErrorCodes
        | 'ONLINE-SYNC-UNKOWN-ERROR';

    export type OnlineSyncProgressErrorCodesRetrieveRowCountErrorCodes = 'RETRIEVE-ROW-COUNT-UNKOWN-ERROR';
    export type OnlineSyncProgressErrorCodesUploadRecordsErrorCodes = 'UPLOAD-RECORDS-UNKOWN-ERROR';
    export type OnlineSyncProgressErrorCodesUploadFilesErrorCodes = 'UPLOAD-FILES-UNKOWN-ERROR';
    export type OnlineSyncProgressErrorCodesRecordUploadErrorCodes = 'RECORD-UPLOAD-UNKOWN-ERROR';
    export type OnlineSyncProgressErrorCodesFileUploadErrorCodes = 'FILE-UPLOAD-UNKOWN-ERROR';

    export type TruncateProgressErrorCodes = 'TRUNCATE-UNKOWN-ERROR';

    export interface IOfflineSyncProgress {
        syncType: 'OFFLINE-SYNC';
        requestGuid: string;

        generateOfflineDataStarted: boolean;
        generateOfflineDataCompleted: boolean;
        generateOfflineDataCompletedWithError: boolean;
        generateOfflineDataError?: ISyncError<OfflineSyncProgressGenerateOfflineDataErrorCodes>;

        retrieveRowCountStarted: boolean;
        retrieveRowCountCompleted: boolean;
        retrieveRowCountCompletedWithError: boolean;
        retrieveRowCountCompletedError?: ISyncError<OfflineSyncProgressRetrieveRowCountErrorCodes>;

        retrieveRecordsStarted: boolean;
        retrieveRecordsCompleted: boolean;
        retrieveRecordsCompletedWithError: boolean;
        retrieveRecordsCompletedError?: ISyncError<OfflineSyncProgressRetrieveRecordsErrorCodes>;

        retrieveFilesStarted: boolean;
        retrieveFilesCompleted: boolean;
        retrieveFilesCompletedWithError: boolean;
        retrieveFilesCompletedError?: ISyncError<OfflineSyncProgressRetrieveFilesErrorCodes>;

        recordsToRetrieve: number;
        recordsRetrieved: number;
        recordsRetrievedWithError: number;
        recordsRetrievedError?: Array<ISyncError<OfflineSyncProgressRecordRetrieveErrorCodes>>;
        recordsStored: number;
        recordsStoredWithError: number;
        recordsStoredError?: Array<ISyncError<OfflineSyncProgressRecordStoreErrorCodes>>;

        filesToRetrieve: number;
        filesRetrieved: number;
        filesRetrievedWithError: number;
        filesRetrievedError?: Array<ISyncError<OfflineSyncProgressFileRetrieveErrorCodes>>;
        filesStored: number;
        filesStoredWithError: number;
        filesStoredError?: Array<ISyncError<OfflineSyncProgressFileStoreErrorCodes>>;
    }

    export interface IOnlineSyncProgress {
        syncType: 'ONLINE-SYNC';
        requestGuid: string;

        retrieveRowCountStarted: boolean;
        retrieveRowCountCompleted: boolean;
        retrieveRowCountCompletedWithError: boolean;
        retrieveRowCountCompletedError?: ISyncError<OnlineSyncProgressErrorCodesRetrieveRowCountErrorCodes>;

        uploadRecordsStarted: boolean;
        uploadRecordsCompleted: boolean;
        uploadRecordsCompletedWithError: boolean;
        uploadRecordsCompletedError?: ISyncError<OnlineSyncProgressErrorCodesUploadRecordsErrorCodes>;

        uploadFilesStarted: boolean;
        uploadFilesCompleted: boolean;
        uploadFilesCompletedWithError: boolean;
        uploadFilesCompletedError?: ISyncError<OnlineSyncProgressErrorCodesUploadFilesErrorCodes>;

        recordsToUpload: number;
        recordsUploaded: number;
        recordsUploadedWithError: number;
        recordsUploadedError?: Array<ISyncError<OnlineSyncProgressErrorCodesRecordUploadErrorCodes>>;

        filesToUpload: number;
        filesUploaded: number;
        filesUploadedWithError: number;
        filesUploadedError?: Array<ISyncError<OnlineSyncProgressErrorCodesFileUploadErrorCodes>>;
    }

    export interface ITruncateProgress {
        syncType: 'TRUNCATE';
        requestGuid: string;

        truncateObjectStoreStarted: boolean;
        truncateObjectStoreCompleted: boolean;
        truncateObjectStoreCompletedWithError: boolean;
        truncateObjectStoreCompletedError?: ISyncError<TruncateProgressErrorCodes>;
    }

    export interface IApiPwaStrategyOptions extends StrategyOptions {
        mode: SyncType;
    }

    export interface IFile {
        PrimKey: string;
        O365_PrimKey: string;
        FileName: string;
        Extension: string;
        FileSize: number;
        FileRef: string;
        PdfRef?: string;
        ThumbnailRef?: string;
    }

    export interface IProcedureOptions {
        operation: string;
        procedureName: string;
        timeout: number;
        useTransaction: boolean;
        values: {
            DeviceRef?: string;
            AppID: string;
            Type?: string;
        };
    }

    export class ApiPwaStrategy extends Strategy {
        constructor(options: IApiPwaStrategyOptions);

        _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined>;
    }
}
